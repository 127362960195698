import React, { Component } from 'react';
import {
    List, ListItem, ListItemIcon, ListItemText,
    Checkbox,
    Container,
    Select,
    MenuItem,
    TextField,
    TableRow

} from '@material-ui/core';

import { FormGroup } from "reactstrap";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { CustomCard } from 'components/GlobalComponents';
import { withStyles } from '@material-ui/core';
import { Grid, Box, Input, FormControl, Button, IconButton } from '@material-ui/core';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,

} from '@material-ui/core'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';

import { Scrollbars } from 'react-custom-scrollbars';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ApiService, customerService, productService, OrderService } from "../../_services";
import { SmallTitleBar } from 'components/GlobalComponents';
import IntlMessages from 'util/IntlMessages';

const styles = (theme) => ({
    root: {
        paddingTop: 15
    },
    margin: {
        marginLeft: 5,
        marginRight: 5
    },
    ListItem: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    large: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        marginRight: 10
    },
    Icon: {
        visibility: 'hidden'
    },
    placeholder: {
        textAlign: 'center'
    },
    paper: {
        position: 'absolute',
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
        maxHeight: 800,
    },
    searchBox: {
        padding: '24px 0px 32px',
        [theme.breakpoints.down('sm')]: {
            padding: '5px 16px 12px 48px'
        },
        [theme.breakpoints.down('xs')]: {
            '& .pagination-wrap': {
                marginTop: '-10px',
            }
        }
    },
    formItem: {
        fontSize: "1.1rem",
        [theme.breakpoints.down('xs')]: {
            fontSize: 16
        }
    },
});


class Section extends Component {

    constructor() {
        super();
        this.myRef = React.createRef()
        this.state = {
            openAddCustomer: false,
            customer: {},
            errors: {},
            orderObject: {},
            searchText: "",
            companies: [],
            productHra: [],
            filtrescompanies: [],
            loader: false,
            checked: [],
            registernummer: '',
            company_name: '',
            registerart: '',
            company: {},
            notices: [],
            cart: [],
            expanded: 0,
            base64: '',
            documents: {},
            officers: [],
            insolvenzcheck: '',
            contactData: {
                phone_number: '',
                website: '',
                email: '',
            },
            bundeslands: [
                'Baden-Württemberg',
                'Bayern',
                'Berlin',
                'Brandenburg',
                'Bremen',
                'Hamburg',
                'Hessen',
                'Mecklenburg-Vorpommern ',

                'Niedersachsen',
                ' Nordrhein-Westfalen',
                'Rheinland-Pfalz',
                'Saarland',
                'Sachsen',
                'Sachsen-Anhalt',
                'Schleswig-Holstein',
                'Thüringen',

            ],
            loaderContactData: false,
            loaderOfficers: false,
            loaderDoc: false,
            loaderNotices: false,
            loaderInsolv: false,
            customers: []

        }
    }
    formatInt(value) {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((value));
    }

    handleChange = (event) => {
        this.setState({ expanded: event });
    };
    handleToggle(value) {
        const currentIndex = this.state.checked.indexOf(value);
        const newChecked = this.state.checked;
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({
            checked: newChecked
        })
    };
    getCustomers() {
        customerService.getAll().then(
            (result) => {
                this.setState({ customers: result.customersExport })
            },
            (error) => {
                console.log('error', error)
            }
        )
    }
    getCompanies(keyword) {
        let object = {
            company_name: keyword
        }
        ApiService.companySearch(object).then(results => {

            if (results.companies.length > 0)
                this.setState({
                    filtrescompanies: results.companies
                })
        }
        )
    }
    getCompanywithSearchdetailed(object) {
        ApiService.getCompanywithSearchdetailed(object).then(result => {

            this.setState({ loader: false })
            if (result.companies.length === 1) {
                this.setState({
                    company: result.companies[0],
                    company_name: result.companies[0].company_name,
                    registerart: result.companies[0].register_type,
                    registernummer: result.companies[0].registernummer,
                    companies: []
                }, () => {
                    this.CompanyContact(result.companies[0]);
                    this.companyMasterSet(result.companies[0])
                    this.companyInsolvenzcheck(result.companies[0])
                    this.executeScroll()
                });
            } else {
                this.setState({ companies: result.companies })
                this.executeScroll()
            }


        })
    }
    setCompanyData(e, i) {
        this.setState({
            company: i,
            company_name: i.company_name,
            registerart: i.register_type,
            registernummer: i.registernummer,
            companies: []
        }, () => {
            this.CompanyContact(i);
            this.companyMasterSet(i)
            this.companyInsolvenzcheck(i)
        });

    }
    getCompany() {
        this.setState({
            loader: true,
            company: {},
            notices: [],
            insolvenzcheck: "",
            documents: {},
            officers: [],
            contactData: {
                phone_number: '',
                website: '',
                email: '',
            }
        })
        // if (this.state.company_name && this.state.registerart && this.state.registernummer) {
        //     let object = JSON.stringify({
        //         "company_name": this.state.company_name,
        //         "register_type": this.state.registerart,
        //         "register_number": this.state.registernummer
        //     })
        //     ApiService.getCompany(object).then(result => {
        //         this.setState({ company: result.company })
        //         this.CompanyContact(result.company);
        //         this.companyMasterSet(result.company)
        //         this.companyInsolvenzcheck(result.company)
        //         this.setState({ loader: false })
        //     })
        // } else {
        let object = JSON.stringify({
            "company_name": this.state.company_name ? this.state.company_name : "",
            "register_type": this.state.registerart ? this.state.registerart : "",
            "register_number": this.state.registernummer ? this.state.registernummer : "",
            "bundesland": this.state.checked
        })
        this.getCompanywithSearchdetailed(object)
        //  }
    }

    companyInsolvenzcheck(company) {
        ApiService.getCompanyInsolvenzcheck(company.company_number).then(result => {
            this.setState({ loaderInsolv: true })
            this.setState({
                insolvenzcheck: result.insolvecy_check

            });
        });

    }
    companyMasterSet(company) {
        ApiService.getCompanyMasterSet(company.company_number).then(result => {

            if (result.company.notices.length >= 0)
                this.setState({ notices: result.company.notices, loaderNotices: true });

            if (result.company.documents)
                this.setState({
                    loaderDoc: true, documents: result.company.documents,
                })
            if (result.company.officers.length >= 0)
                this.setState({
                    loaderOfficers: true, officers: result.company.officers,
                })
        });
    }


    CompanyContact(company) {
        ApiService.getContact(company.company_number).then(result => {

            this.setState({ contactData: result, loaderContactData: true });
        });
    }

    saveDokumente(v, title = null) {
        let object = JSON.stringify({
            company_number: this.state.company.company_number
        })
        ApiService.downloadDocument(object, v.id_document).then(result => {
            this.setState({ base64: result.doc_64b })
            const linkSource = `data:${result.extension};base64,${result.doc_64b}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = (title ? title : v.title) + "." + result.extension;
            downloadLink.click();
        })
    }
    isItemExistInCart(id) {
        const cart = this.state.cart;
        let existence = false
        for (const item of cart) {
            if (item.id_document === id) {
                existence = true
            }
        }
        return existence;
    }

    check(e, product, id_document, prefix) {

        let hraProduct = this.state.productHra.filter(e => e.hra_prefix === prefix)
        let cartItem = {
            companyName: this.state.company.company_name,
            name: (prefix === "AD" || prefix === "CD" || prefix === "SI" || prefix === "HD") ? hraProduct[0].title : product.title,
            category: hraProduct[0].Category?.title,
            price: hraProduct[0].price_net,
            totalPrice: hraProduct[0].price_brut,
            productID: hraProduct[0]._id,
            company_number: this.state.company.company_number,
            id_document: id_document
        }

        const check = document.getElementById(id_document)
        if (check.checked) {
            this.onPressAddToCart(e, cartItem)
        }
        else {
            this.onDeleteCartItem(cartItem)
        }

    }
    // on click add to cart
    onPressAddToCart(e, cartItem) {
        setTimeout(() => {

            const newCart = this.state.cart;

            newCart.push(cartItem);

            this.setState({
                cart: newCart
            })
        }, 100)
        e.preventDefault()
    }
    handleOrder() {
        this.setState({ openAddCustomer: true })
        this.getCustomers()
        this.initOrderObject()

    }
    clickCreateOrder() {
        if (this.state.customer && this.state.customer._id) {

            let object = JSON.stringify(this.initOrderObject())
            this.createOrder(object)
        } else {
            let errors = {}
            errors["customer"] = "Bitte geben kunde ein.";
            this.setState({ errors: errors })
        }
    }
    // on click delete item from card
    onDeleteCartItem(item) {
        const currentIndex = this.state.cart.indexOf(item);

        const newCart = this.state.cart;

        newCart.splice(currentIndex, 1);
        this.setState({
            cart: newCart
        })
    }
    handleSearch = event => {
        let searchString = event.target.value;
        this.setState({ company_name: searchString });
        // if (searchString.length >= 3) {
        //     this.getCompanies(searchString)
        // }
    }
    executeScroll = () => { if (this.myRef.current != null) this.myRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }) }   // get company by name,registerArt,registerNummer
    handleChangeCompanyData = (e, company) => {
        if (company) {
            this.setState({ registerart: company.register_number.substring(0, company.register_number.indexOf(' ')) });
            this.setState({ registernummer: company.register_number.substring(company.register_number.indexOf(' ') + 1, company.register_number.length) });
            this.setState({ company_name: company.company_name });
        }
        else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }
    // Display list of all product hra.
    getAllProductHra() {
        productService.getAllProductsHra().then(results => {
            this.setState({ productHra: results.products })
        }

        )
    }
    componentDidMount() {
        this.getAllProductHra()
    }
    initOrderObject() {
        let price_brut = 0;
        let price_net = 0;
        let order_details = [];
        this.state.cart.forEach(element => {
            price_net = (parseFloat(price_net) + parseFloat(element.price));
            price_brut = (parseFloat(price_brut) + parseFloat(element.totalPrice));
            order_details.push({
                "companyName": element.companyName,
                "category": element.category,
                "productID": element.productID,
                "price_net": element.price,
                "title": element.name,
                "quantity": 1,
                "Document": {
                    "company_number": element.company_number,
                    "document_id": element.id_document,
                    "company_name": element.companyName
                }
            })
        });


        let object = {
            price_net: price_net,
            price_brut: price_brut,
            tva: price_brut - price_net,
            status: 'paid',
            type: "Document",
            Order_Details: order_details,
            Customer: this.state.customer._id

        };
        this.setState({ orderObject: object })
        return object;
    }
    createOrder(object) {
        OrderService.createOrder(object).then(order => {
            this.setState({
                cart: [],
                orderObject: {},
                openAddCustomer: false,

            })
        })
    }
    render() {

        const { filtrescompanies } = this.state;
        const { classes } = this.props;
        return (
            <div className="tables-wrapper">
                <SmallTitleBar title={<IntlMessages id="widgets.search" />} />

                <Container maxWidth="lg">
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container spacing={3} className="stats-wrap mt-1">

                            <Grid item xs={12} sm={12} md={12} style={{ padding: '0px 12px' }}>
                                <CustomCard>
                                    <Grid container spacing={3} >
                                        <Grid item xs={12} sm={12} md={6}>
                                        <Grid item xs={6} sm={6} md={12}>
                                                <FormGroup className="mt-3 margin-bottom-company">
                                                    <Box fontWeight="500" display="inline-block" color="text.primary" fontSize="1.2rem" component="span">

                                                    </Box>

                                                </FormGroup>
                                            </Grid>
                                            <Box style={{ display: "flex" ,paddingTop:"20px"}}>
                                                <Grid item xs={6} sm={6} md={4}>
                                                    <FormGroup className="mt-1 margin-bottom-company">
                                                        <Box fontWeight="500" display="inline-block" color="text.primary" fontSize="body2.fontSize" component="span">Unternehmen</Box>

                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={8}>

                                                    <FormControl fullWidth >
                                                        <Input
                                                            onChange={this.handleSearch} type="text"

                                                            placeholder="UNTERNEHMEN SUCHEN UND FINDEN"
                                                            value={this.state.company_name}

                                                        /></FormControl>




                                                </Grid>
                                            </Box>

                                            <Box style={{ display: "flex" }}>
                                                <Grid item xs={6} sm={6} md={4}>
                                                    <FormGroup className="mt-1 margin-bottom-company">
                                                        <Box fontWeight="500" display="inline-block" color="text.primary" fontSize="body2.fontSize" component="span">Registerart</Box>


                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={8}>
                                                    <div >
                                                        <FormControl fullWidth>
                                                            <Select name="registerart" placeholder='Registerart'
                                                                onChange={(e) => this.handleChangeCompanyData(e)}
                                                                value={this.state.registerart}>
                                                                <MenuItem value="HRA">HRA</MenuItem>
                                                                <MenuItem value="HRB">HRB</MenuItem>
                                                                <MenuItem value="GnR">GnR</MenuItem>
                                                                <MenuItem value="PR">PR</MenuItem>
                                                                <MenuItem value="VR">VR</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </Grid>

                                            </Box>

                                            <Box style={{ display: "flex" }}>
                                                <Grid item xs={6} sm={6} md={4}>
                                                    <FormGroup className="mt-1 margin-bottom-company">
                                                        <Box fontWeight="500" display="inline-block" color="text.primary" fontSize="body2.fontSize" component="span">Registernummer</Box>


                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={8}>
                                                    <div >
                                                        <FormControl fullWidth >
                                                            <Input
                                                                onChange={(e) => this.handleChangeCompanyData(e)}


                                                                name="registernummer"
                                                                type="text"
                                                                value={this.state.registernummer}
                                                                placeholder="Registernummer"

                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Grid>

                                            </Box>


                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <Grid item xs={12} sm={12} md={12} style={{    paddingLeft: "16px",paddingRight:" 16px"}}>
                                                <FormGroup className="mt-3 margin-bottom-company">
                                                    <Box fontWeight="500" display="inline-block" color="text.primary" fontSize="1.2rem" component="span">Suche auf folgenden Bundesländer begrenzen

                                                    </Box>

                                                </FormGroup>
                                            </Grid>
                                            <Box mb={3} style={{ display: "flex" }}>

                                                <Grid item xs={6} sm={6} md={8}>
                                                    <Box className="checkbox-list-wrap">
                                                        <List>
                                                            {this.state.bundeslands.slice(0, 8).map(value => {
                                                                const labelId = `checkbox-list-label-${value}`;
                                                                return (
                                                                    <ListItem key={value} dense button onClick={() => this.handleToggle(value)}  >
                                                                        <ListItemIcon style={{ minWidth: 'initial' }}>
                                                                            <Checkbox
                                                                                className="checkboxlist"
                                                                                color="primary"
                                                                                edge="start"
                                                                                checked={this.state.checked.indexOf(value) !== -1}
                                                                                tabIndex={-1}
                                                                                disableRipple
                                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                                            />
                                                                        </ListItemIcon>
                                                                        <ListItemText id={labelId} primary={value} />

                                                                    </ListItem>
                                                                );
                                                            })}
                                                        </List>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6}>
                                                    <Box className="checkbox-list-wrap">
                                                        <List>
                                                            {this.state.bundeslands.slice(8, 16).map(value => {
                                                                const labelId = `checkbox-list-label-${value}`;
                                                                return (
                                                                    <ListItem key={value} dense button onClick={() => this.handleToggle(value)}  >
                                                                        <ListItemIcon style={{ minWidth: 'initial' }}>
                                                                            <Checkbox
                                                                                className="checkboxlist"
                                                                                color="primary"
                                                                                edge="start"
                                                                                checked={this.state.checked.indexOf(value) !== -1}
                                                                                tabIndex={-1}
                                                                                disableRipple
                                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                                            />
                                                                        </ListItemIcon>
                                                                        <ListItemText id={labelId} primary={value} />

                                                                    </ListItem>
                                                                );
                                                            })}
                                                        </List>
                                                    </Box>
                                                </Grid>
                                            </Box>
                                        </Grid>


                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>

                                        <Box mb={2} textAlign="center">
                                            {(this.state.company_name || this.state.registerart || this.state.registernummer) ?
                                                <Button className="thm-btn" variant="outlined" size="large" onClick={() => this.getCompany()}  >SUCHEN</Button>
                                                :
                                                <Button className="thm-btn" variant="outlined" size="large" disabled >SUCHEN</Button>
                                            }
                                        </Box>
                                    </Grid>
                                </CustomCard>

                            </Grid>

                        </Grid>
                    </Grid>
                    <div id="result">
                        <div className="bor " style={{ marginTop: "13px", marginBottom: "13px" }}></div>
                        <div className="loader-center">
                            {this.state.loader ?
                                <Box mb={2} className="btn-group" textAlign="center">
                                    <Loader
                                        type="ThreeDots"
                                        color="#cda966"
                                        height={50}
                                        width={50}
                                        visible={this.state.show}
                                    />

                                </Box> : null}
                        </div>

                    </div>

                    {this.state.company.company_name ?
                        <Box px={{ xs: '12px', lg: 0 }} >
                            <Grid container spacing={3} direction="row">

                                <Grid item xs={12} sm={6} md={6}>

                                    <CustomCard  >
                                        <div ><h3 className="text-center ">  Stammdaten des Unternehmen {this.state.company.company_name} </h3>
                                            <div className="lighlight-border mt-3"></div>
                                        </div>

                                        <List className={classes.root}>
                                            <ListItem className={classes.ListItem}>
                                                <IconButton className={classes.margin} size="small">
                                                    <Box component="span" id="spanColor" className="material-icons-outlined">location_on</Box>
                                                </IconButton>

                                                <ListItemText >
                                                    <div style={{ display: "flex" }}>
                                                        <div style={{ flex: "0 0 25%", maxWidth: "25%" }}>Adressen :</div>
                                                        <div style={{ flex: "0 0 75%", maxWidth: "75%", textAlign: "center" }}>{this.state.company.registered_address ? this.state.company.registered_address : "keine Daten verfügbar"}</div>
                                                    </div>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem className={classes.ListItem}>
                                                <IconButton className={classes.margin} size="small">
                                                    <Box component="span" id="spanColor" className="material-icons-outlined">looks_one</Box>
                                                </IconButton>
                                                <ListItemText>
                                                    <div style={{ display: "flex" }}>
                                                        <div style={{ flex: "0 0 25%", maxWidth: "25%" }}>HR-Nummer :</div>
                                                        <div style={{ flex: "0 0 75%", maxWidth: "75%", textAlign: "center" }}>{this.state.company.register_number ? this.state.company.register_number : 'keine Daten verfügbar'}</div>
                                                    </div>

                                                </ListItemText>
                                            </ListItem>
                                            <ListItem className={classes.ListItem}>
                                                <IconButton className={classes.margin} size="small">
                                                    <Box component="span" id="spanColor" className="material-icons-outlined">attach_money</Box>
                                                </IconButton>
                                                <ListItemText>
                                                    <div style={{ display: "flex" }}>
                                                        <div style={{ flex: "0 0 25%", maxWidth: "25%" }}>kapital :</div>
                                                        <div style={{ flex: "0 0 75%", maxWidth: "75%", textAlign: "center" }}>{this.state.company.capital ? this.state.company.capital : "keine Daten verfügbar"}</div>
                                                    </div>
                                                </ListItemText></ListItem>
                                            {
                                                this.state.loaderContactData === false ?
                                                    <div className="loader-center">
                                                        <Loader
                                                            type="ThreeDots"
                                                            color="#cda966"
                                                            height={50}
                                                            width={50}
                                                            visible={true}
                                                        />
                                                    </div>
                                                    :
                                                    <React.Fragment>
                                                        <ListItem className={classes.ListItem}>
                                                            <IconButton className={classes.margin} size="small">
                                                                <Box component="span" id="spanColor" className="material-icons-outlined">language</Box>
                                                            </IconButton>
                                                            <ListItemText>
                                                                <div style={{ display: "flex" }}>
                                                                    <div style={{ flex: "0 0 25%", maxWidth: "25%" }}>Website :</div>
                                                                    <div style={{ flex: "0 0 75%", maxWidth: "75%", textAlign: "center" }}>{this.state.contactData.website ? this.state.contactData.website : 'keine Daten verfügbar'}</div>
                                                                </div>

                                                            </ListItemText></ListItem>
                                                        <ListItem className={classes.ListItem}>
                                                            <IconButton className={classes.margin} size="small">
                                                                <Box component="span" id="spanColor" className="material-icons-outlined ">email</Box>
                                                            </IconButton>
                                                            <ListItemText>
                                                                <div style={{ display: "flex" }}>
                                                                    <div style={{ flex: "0 0 25%", maxWidth: "25%" }}> Email :</div>
                                                                    <div style={{ flex: "0 0 75%", maxWidth: "75%", textAlign: "center" }}>{this.state.contactData?.email ? this.state.contactData.email : 'keine Daten verfügbar'}</div>
                                                                </div>
                                                            </ListItemText></ListItem>
                                                        <ListItem className={classes.ListItem}>
                                                            <IconButton className={classes.margin} size="small">
                                                                <Box component="span" id="spanColor" className="material-icons-outlined">phone</Box>
                                                            </IconButton>
                                                            <ListItemText>
                                                                <div style={{ display: "flex" }}>
                                                                    <div style={{ flex: "0 0 25%", maxWidth: "25%" }}>Telefon :</div>
                                                                    <div style={{ flex: "0 0 75%", maxWidth: "75%", textAlign: "center" }}> {this.state.contactData.phone_number ? this.state.contactData.phone_number : "keine Daten verfügbar"}</div>
                                                                </div>

                                                            </ListItemText></ListItem>
                                                    </React.Fragment>
                                            }
                                            {this.state.loaderInsolv === false ?
                                                <div className="loader-center">
                                                    <Loader
                                                        type="ThreeDots"
                                                        color="#cda966"
                                                        height={50}
                                                        width={50}
                                                        visible={true}
                                                    />
                                                </div>

                                                :
                                                <ListItem className={classes.ListItem}>
                                                    <IconButton className={classes.margin} size="small">
                                                        <Box component="span" id="spanColor" className="material-icons-outlined">done</Box>
                                                    </IconButton>
                                                    <ListItemText>
                                                        <div style={{ display: "flex" }}>
                                                            <div style={{ flex: "0 0 25%", maxWidth: "25%" }}>Insolvenzcheck :</div>
                                                            <div style={{ flex: "0 0 75%", maxWidth: "75%", textAlign: "center" }}>{this.state.insolvenzcheck ? this.state.insolvenzcheck : '-'}</div>
                                                        </div>

                                                    </ListItemText></ListItem>

                                            }
                                        </List>

                                    </CustomCard>
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <CustomCard >
                                        <div ><h3 className="text-center ">Geschäftsführung des Unternehmen {this.state.company.company_name} </h3>
                                            <div className="lighlight-border mt-3"></div>
                                        </div>
                                        {this.state.loaderOfficers === false ?
                                            <div className="loader-center">
                                                <Loader
                                                    type="ThreeDots"
                                                    color="#cda966"
                                                    height={50}
                                                    width={50}
                                                    visible={true}
                                                />


                                            </div>
                                            :
                                            <Scrollbars
                                                className="rct-scroll"
                                                autoHide
                                                style={{ height: "350px" }}
                                            >
                                                <List className={classes.root}>
                                                    {this.state.officers.length > 0 ?

                                                        this.state.officers.map((item, i) => (
                                                            <ListItem className={classes.ListItem} key={i}>
                                                                <div style={{ display: "flex", flex: "0 0 50%", maxWidth: "50%" }}>
                                                                    <IconButton className={classes.margin} size="small" >
                                                                        <Box component="span" id="spanColor" className="material-icons-outlined">person</Box>
                                                                    </IconButton>
                                                                    <ListItemText >
                                                                        <div >Name :</div>
                                                                        <div ><Typography>{item.name}</Typography></div>

                                                                    </ListItemText>
                                                                </div>
                                                                <div style={{ display: "flex", flex: "0 0 50%", maxWidth: "50%" }}>
                                                                    <IconButton className={classes.margin} size="small" >
                                                                        <Box component="span" id="spanColor" className="material-icons-outlined">approval</Box>
                                                                    </IconButton>
                                                                    <ListItemText>
                                                                        <div >Position :</div>
                                                                        <div ><Typography>{item.position}</Typography></div>

                                                                    </ListItemText>
                                                                </div>
                                                            </ListItem>


                                                        ))
                                                        : <div className="loader-center">keine Daten verfügbar</div>}
                                                </List>
                                            </Scrollbars>


                                        }
                                    </CustomCard>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} direction="row"   >

                                <Grid item xs={12} sm={6} md={6}>
                                    <CustomCard>
                                        <div >
                                            <h3 className="text-center">UNSER DOKUMENTENSERVICE {this.state.company.company_name} </h3>
                                            <div className="lighlight-border mt-3"></div>
                                        </div>
                                        {this.state.loaderDoc === false ?

                                            <div className="loader-center">
                                                <Loader
                                                    type="ThreeDots"
                                                    color="#cda966"
                                                    height={50}
                                                    width={50}
                                                    visible={true}
                                                />


                                            </div>
                                            :
                                            <Grid >
                                                <div className=" margin-product">
                                                    {this.state.loader === true ?
                                                        <div className={classes.root}>

                                                            <div className="text" style={{ marginTop: "10px" }}>

                                                                <span className="words-wrapper" >
                                                                    <div>Handelsregsiter wird konsultiert....</div>
                                                                </span>

                                                            </div>
                                                        </div>
                                                        :
                                                        <div className={classes.root}>
                                                            {Object.keys(this.state?.documents).length > 0 ?
                                                                <>
                                                                    {Object.entries(this.state.documents).map(([key, value]) => {

                                                                        return (
                                                                            <div key={key}>
                                                                                {key === "AD" ?
                                                                                    <div key={key}>
                                                                                        <Typography variant="h6" className="product-title">Aktueller Handelsregister Ausdruck (AD)</Typography>


                                                                                        <Box display="flex" py={1} justifyContent="space-between"  >
                                                                                            <Box className="product-sub-title">
                                                                                                <Typography >Aktueller Handelsregister Ausdruck </Typography></Box>
                                                                                            <Box >  {!this.isItemExistInCart(value.id_document) ? (
                                                                                                <Checkbox
                                                                                                    className="checkboxlist"
                                                                                                    color="primary"
                                                                                                    edge="start" id={value.id_document} checked={false} name={value.id_document} onChange={(e) => this.check(e, value, value.id_document, 'AD')} />

                                                                                            ) : (
                                                                                                <Checkbox
                                                                                                    className="checkboxlist"
                                                                                                    color="primary"
                                                                                                    edge="start" id={value.id_document} checked={true} name={value.id_document} onChange={(e) => this.check(e, value, value.id_document, 'AD')} />
                                                                                            )}
                                                                                                <Box component="span" id="spanColor" className="material-icons-outlined" onClick={() => this.saveDokumente(value, "Aktueller Handelsregister Ausdruck")} style={{ "cursor": "pointer", verticalAlign: "middle" }}>file_download</Box>
                                                                                            </Box></Box>


                                                                                    </div>

                                                                                    : null}

                                                                            </div>
                                                                        )
                                                                    })}
                                                                    {Object.entries(this.state.documents).map(([key, value]) => {

                                                                        return (
                                                                            <div key={key}>
                                                                                {key === "CD" ?
                                                                                    <div key={key}>
                                                                                        <Typography variant="h6" className="product-title">Chronologischer Abdruck (CD)</Typography>

                                                                                        <Box display="flex" py={1} justifyContent="space-between"  >

                                                                                            <Box className="product-sub-title">   <Typography >Chronologischer Abdruck </Typography></Box>
                                                                                            <Box>  {!this.isItemExistInCart(value.id_document) ? (
                                                                                                <Checkbox
                                                                                                    className="checkboxlist"
                                                                                                    color="primary"
                                                                                                    edge="start" id={value.id_document} checked={false} name={value.id_document} onChange={(e) => this.check(e, value, value.id_document, 'CD')} />

                                                                                            ) : (
                                                                                                <Checkbox
                                                                                                    className="checkboxlist"
                                                                                                    color="primary"
                                                                                                    edge="start" id={value.id_document} checked={true} name={value.id_document} onChange={(e) => this.check(e, value, value.id_document, 'CD')} />
                                                                                            )}
                                                                                                <Box component="span" style={{ "cursor": "pointer", verticalAlign: "middle" }} id="spanColor" className="material-icons-outlined" onClick={() => this.saveDokumente(value, "Chronologischer Abdruck")}>file_download</Box>
                                                                                            </Box>
                                                                                        </Box>




                                                                                    </div> : null}

                                                                            </div>
                                                                        )
                                                                    })}
                                                                    {Object.entries(this.state.documents).map(([key, value]) => {

                                                                        return (
                                                                            <div key={key}>
                                                                                {key === "HD" ?
                                                                                    <div className="mg-top product-title font-siz-comp" key={key}>
                                                                                        <Typography variant="h6" className="product-title">Historischer Handelsregisterauszug (HD)</Typography>


                                                                                        <Box display="flex" py={1} justifyContent="space-between" >

                                                                                            <Box className="product-sub-title">  <Typography >Historischer Handelsregisterauszug</Typography></Box>
                                                                                            <Box >{!this.isItemExistInCart(value.id_document) ? (
                                                                                                <Checkbox
                                                                                                    className="checkboxlist"
                                                                                                    color="primary"
                                                                                                    edge="start" id={value.id_document} checked={false} name={value.id_document} onChange={(e) => this.check(e, value, value.id_document, 'HD')} />

                                                                                            ) : (
                                                                                                <Checkbox
                                                                                                    className="checkboxlist"
                                                                                                    color="primary"
                                                                                                    edge="start" id={value.id_document} checked={true} name={value.id_document} onChange={(e) => this.check(e, value, value.id_document, 'HD')} />
                                                                                            )}
                                                                                                <Box component="span" id="spanColor" style={{ "cursor": "pointer", verticalAlign: "middle" }} className="material-icons-outlined" onClick={() => this.saveDokumente(value, "Historischer Handelsregisterauszug")}>file_download</Box>
                                                                                            </Box>

                                                                                        </Box>

                                                                                    </div>

                                                                                    : null}

                                                                            </div>
                                                                        )
                                                                    })}
                                                                    {Object.entries(this.state.documents).map(([key, value]) => {

                                                                        return (
                                                                            <div key={key}>
                                                                                {key === "SI" ?
                                                                                    <div key={key}>
                                                                                        <Typography variant="h6" className="product-title">Strukturierter Registerinhalt</Typography>


                                                                                        <Box display="flex" py={1} justifyContent="space-between"  >
                                                                                            <Box className="product-sub-title"> <Typography >Strukturierter Registerinhalt</Typography> </Box >
                                                                                            <Box >
                                                                                                {!this.isItemExistInCart(value.id_document) ? (
                                                                                                    <Checkbox
                                                                                                        className="checkboxlist"
                                                                                                        color="primary"
                                                                                                        edge="start" id={value.id_document} checked={false} name={value.id_document} onChange={(e) => this.check(e, value, value.id_document, 'SI')} />

                                                                                                ) : (
                                                                                                    <Checkbox
                                                                                                        className="checkboxlist"
                                                                                                        color="primary"
                                                                                                        edge="start" id={value.id_document} checked={true} name={value.id_document} onChange={(e) => this.check(e, value, value.id_document, 'SI')} />
                                                                                                )}
                                                                                                <Box component="span" id="spanColor" style={{ "cursor": "pointer", verticalAlign: "middle" }} className="material-icons-outlined" onClick={() => this.saveDokumente(value, "Strukturierter Registerinhalt")}>file_download</Box>
                                                                                            </Box>

                                                                                        </Box>
                                                                                    </div>
                                                                                    : null}

                                                                            </div>
                                                                        )
                                                                    })}
                                                                    <div >
                                                                        {(this.state.documents.DK.filter((element => element.title.indexOf('Anmeldung') > -1 || element.title.indexOf('Protokoll') > -1 || element.title.indexOf('protokoll') > -1)).length) > 0 ?

                                                                            <Typography variant="h6" className="product-title">Anmeldungen & Protokolle
                                                                            </Typography>
                                                                            : <></>
                                                                        }
                                                                        <div className="mg-top font-siz-comp" >

                                                                            {this.state.documents.DK.map((product, key) => {

                                                                                return (product.title.indexOf('Anmeldung') > -1 || product.title.indexOf('Protokoll') > -1 || product.title.indexOf('protokoll') > -1 ?
                                                                                    <Box key={product.id_document}>

                                                                                        <Box display="flex" py={1} justifyContent="space-between" >
                                                                                            <Box className="product-sub-title">  <Typography >{product.title}</Typography></ Box >

                                                                                            <Box >
                                                                                                {!this.isItemExistInCart(product.id_document) ? (
                                                                                                    <Checkbox
                                                                                                        className="checkboxlist"
                                                                                                        color="primary"
                                                                                                        edge="start" id={product.id_document} checked={false} name={product.id_document} onChange={(e) => this.check(e, product, product.id_document, 'AP')} />

                                                                                                ) : (
                                                                                                    <Checkbox
                                                                                                        className="checkboxlist"
                                                                                                        color="primary"
                                                                                                        edge="start" id={product.id_document} checked={true} name={product.id_document} onChange={(e) => this.check(e, product, product.id_document, 'AP')} />
                                                                                                )}

                                                                                                <Box component="span" id="spanColor" style={{ "cursor": "pointer", verticalAlign: "middle" }} className="material-icons-outlined" onClick={() => this.saveDokumente(product)}>file_download</Box>
                                                                                            </Box>
                                                                                        </Box>

                                                                                    </Box>


                                                                                    : <></>)
                                                                            })}</div>


                                                                    </div>
                                                                    <div >
                                                                        {(this.state.documents.DK.filter((element => element.title.indexOf('Gesellschaftsvertrag') > -1)).length) > 0 ?

                                                                            <Typography variant="h6" className="product-title">Gesellschaftsvertrag
                                                                            </Typography>
                                                                            : <></>
                                                                        }
                                                                        <div className="mg-top font-siz-comp" >

                                                                            {this.state.documents.DK.map((product, key) => {

                                                                                return (product.title.indexOf('Gesellschaftsvertrag') > -1 ?
                                                                                    <Box key={product.id_document}>

                                                                                        <Box display="flex" py={1} justifyContent="space-between" >
                                                                                            <Box className="product-sub-title">  <Typography >{product.title}</Typography></ Box >

                                                                                            <Box >
                                                                                                {!this.isItemExistInCart(product.id_document) ? (
                                                                                                    <Checkbox
                                                                                                        className="checkboxlist"
                                                                                                        color="primary"
                                                                                                        edge="start" id={product.id_document} checked={false} name={product.id_document} onChange={(e) => this.check(e, product, product.id_document, 'GSS')} />

                                                                                                ) : (
                                                                                                    <Checkbox
                                                                                                        className="checkboxlist"
                                                                                                        color="primary"
                                                                                                        edge="start" id={product.id_document} checked={true} name={product.id_document} onChange={(e) => this.check(e, product, product.id_document, 'GSS')} />
                                                                                                )}

                                                                                                <Box component="span" id="spanColor" style={{ "cursor": "pointer", verticalAlign: "middle" }} className="material-icons-outlined" onClick={() => this.saveDokumente(product)}>file_download</Box>
                                                                                            </Box>
                                                                                        </Box>

                                                                                    </Box>


                                                                                    : <></>)
                                                                            })}</div>


                                                                    </div>

                                                                    <div >
                                                                        {(this.state.documents.DK.filter((element => element.title.indexOf("Liste der Gesellschafter") !== -1)).length) > 0 ?

                                                                            <Typography variant="h6" className="product-title">Liste der Gesellschafter
                                                                            </Typography>
                                                                            : <></>}

                                                                        <div className="mg-top font-siz-comp" >

                                                                            {this.state.documents.DK.map((product, key) => {

                                                                                return (product.title.indexOf("Liste der Gesellschafter") !== -1 ?
                                                                                    <Box key={product.id_document}>

                                                                                        <Box display="flex" py={1} justifyContent="space-between" >
                                                                                            <Box className="product-sub-title">  <Typography >{product.title}</Typography></ Box >

                                                                                            <Box >
                                                                                                {!this.isItemExistInCart(product.id_document) ? (
                                                                                                    <Checkbox
                                                                                                        className="checkboxlist"
                                                                                                        color="primary"
                                                                                                        edge="start" id={product.id_document} checked={false} name={product.id_document} onChange={(e) => this.check(e, product, product.id_document, 'LG')} />

                                                                                                ) : (
                                                                                                    <Checkbox
                                                                                                        className="checkboxlist"
                                                                                                        color="primary"
                                                                                                        edge="start" id={product.id_document} checked={true} name={product.id_document} onChange={(e) => this.check(e, product, product.id_document, 'LG')} />
                                                                                                )}

                                                                                                <Box component="span" id="spanColor" style={{ "cursor": "pointer", verticalAlign: "middle" }} className="material-icons-outlined" onClick={() => this.saveDokumente(product)}>file_download</Box>
                                                                                            </Box>
                                                                                        </Box>

                                                                                    </Box>


                                                                                    : <></>)
                                                                            })}</div>


                                                                    </div>


                                                                    {Object.entries(this.state.documents).map(([key, value]) => {

                                                                        return (
                                                                            <div key={key}>
                                                                                {key === "Bilans" ?
                                                                                    <div className="mg-top font-siz-comp" key={key}>
                                                                                        <Typography variant="h6" className="product-title">Bilanz / Jahresabschluss</Typography>
                                                                                        {value.map(product =>
                                                                                            <Box key={product.id_document}>

                                                                                                <Box display="flex" py={1} justifyContent="space-between" >
                                                                                                    <Box className="product-sub-title">  <Typography >{product.title}</Typography></Box>
                                                                                                    <Box>

                                                                                                        <Box component="span" id="spanColor" style={{ "cursor": "pointer", verticalAlign: "middle" }} className="material-icons-outlined" onClick={() => this.saveDokumente(product)}>file_download</Box>
                                                                                                    </Box>
                                                                                                </Box>


                                                                                            </Box>

                                                                                        )}
                                                                                    </div>

                                                                                    : null}

                                                                            </div>
                                                                        )
                                                                    })}
                                                                    <Box mb={2} textAlign="center">
                                                                        {(this.state.cart.length === 0 && this.state.cart.length === 0) ?

                                                                            <Button className="thm-btn" variant="outlined" size="large" disabled >ZUR KASSE</Button>
                                                                            :
                                                                            <Button className="thm-btn" variant="outlined" size="large" onClick={() => this.handleOrder()}  >ZUR KASSE</Button>
                                                                        }</Box>
                                                                </>
                                                                : <div className="loader-center">keine Daten verfügbar</div>}
                                                        </div>}




                                                    <div>
                                                    </div>


                                                </div>


                                            </Grid>
                                        }
                                    </CustomCard>
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <CustomCard>
                                        <div>
                                            <div ><h3 className="text-center ">Die aktuellsten Neueintragungen im Handelsregister Neubrandenburg </h3>
                                                <div className="lighlight-border mt-3"></div>
                                            </div>
                                            {this.state.loaderNotices === false ?
                                                <div className="loader-center">
                                                    <Loader
                                                        type="ThreeDots"
                                                        color="#cda966"
                                                        height={50}
                                                        width={50}
                                                        visible={true}
                                                    />
                                                </div>
                                                : <div className={classes.root}> {this.state.notices.length > 0 ?
                                                    this.state.notices.map((element, i) => (
                                                        <Accordion className={classes.faqListItem} key={i} expanded={this.state.expanded === i} onChange={() => this.handleChange(i)}>
                                                            <AccordionSummary
                                                                className={classes.title}
                                                                expandIcon={<i className='fas fa-angle-down' />}
                                                                aria-controls={i + '_content'}
                                                                id={i + '_header'}
                                                            >


                                                                <Typography variant="body2" className={classes.heading}> {element.publication_date}</Typography>
                                                                <Box display={{ xs: 'none', sm: 'inline-block' }} className="counter "><Typography variant="body2" className='color-smav'> :  {element.notice_type}</Typography></Box>

                                                            </AccordionSummary>
                                                            <AccordionDetails className={classes.content}>
                                                                <Typography variant="body2">
                                                                    {element.notice_text}
                                                                </Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    )) : <div className="loader-center">keine Daten verfügbar</div>}</div>}

                                        </div>
                                    </CustomCard>
                                </Grid>
                            </Grid>
                            <div ref={this.myRef}></div>
                        </Box>
                        : this.state.companies.length > 0 ? <CustomCard cardClasses="welcome-note">
                            <Box>
                                <Grid container spacing={3}>
                                    {this.state.companies.length > 0 && this.state.companies.map((i, index) => {
                                        return (
                                            <Grid item xs={12} sm={6} md={6}>
                                                <Box className="welcome-note-list" style={{ "cursor": "pointer" }} onClick={(e) => this.setCompanyData(e, i)}>
                                                    <Box>
                                                        <i className={`material-icons badge-danger`}>business</i>
                                                    </Box>
                                                    <Typography variant="body2" color="textSecondary"><span className=" text-dark">{i.company_name}</span>{i.register_number}</Typography>
                                                </Box>
                                            </Grid>

                                        )
                                    })}
                                </Grid>
                            </Box> <div ref={this.myRef}></div></CustomCard> : null
                    }

                </Container>

                <Dialog
                    open={this.state.openAddCustomer}
                    onClose={() => this.setState({ openAddCustomer: false })}
                    aria-labelledby="responsive-dialog-title"

                >
                    <DialogContent>

                        <Grid item className="cart-action-panel">
                            <Box mb={5}>
                                <Typography variant="h5">
                                    Kunde :
                                </Typography>
                            </Box>
                            <Autocomplete
                                selectOnFocus
                                clearOnBlur
                                size="small"
                                handleHomeEndKeys
                                options={this.state.customers}
                                getOptionLabel={(option) => option.first_name}

                                renderInput={(params) => (
                                    < TextField {...params} type="text"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                        variant="outlined"
                                        placeholder='Kundensuche'
                                    />
                                )}
                                onChange={(e, value) => this.setState({ customer: value })} />
                            <div className="text-danger">{this.state.errors?.customer}</div>


                        </Grid>

                        <Grid className="cart-wrapper">

                            <Box className="cart-main">
                                <Box mb={5}>
                                    <Typography variant="h5">
                                        IHR WARENKORB :
                                    </Typography>
                                    <Typography variant="subtitle2" className="text-dark">({this.state.cart.length} ARTIKEL)</Typography>
                                </Box>
                                <div className="cart-list-wrap">
                                    {this.state.cart && this.state.cart.map((row, index) => (
                                        <div className="cart-list-item" key={index}>

                                            <Box className="cart-item-content" >
                                                <div className="cart-detail text-dark">
                                                    <Typography variant="h6">{row.name}</Typography>
                                                    <Typography variant="body2">{row.companyName}</Typography>
                                                </div>
                                            </Box>
                                            <Box className="cart-item-action" >
                                                <Box display="flex" justifyContent="flex-end">

                                                    <Typography className="cart-item-price">
                                                        {this.formatInt(row.price)}
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </div>



                                    ))}
                                </div>
                                <Box className="subtotal" p={4}>
                                    <Box display="flex" className="text-dark font-lg" justifyContent="space-between">
                                        <p>Gesamtsumme NETTO:</p>
                                        <p>{this.formatInt(this.state.orderObject?.price_net)}</p>
                                    </Box>
                                    <Box display="flex" className="text-dark font-lg" justifyContent="space-between">
                                        <p>zzgl. UST. <span className="font-sm">19%</span>	 </p>
                                        <p>{this.formatInt(this.state.orderObject?.tva)}</p>
                                    </Box>
                                    <Box display="flex" className="text-dark font-lg" justifyContent="space-between">
                                        <p><strong> Gesamtsumme Brutto:</strong></p>
                                        <p><strong>{this.formatInt(this.state.orderObject?.price_brut)}</strong></p>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>







                    </DialogContent>
                    <DialogActions >

                        <Button className="btn thm-btn" onClick={() => this.clickCreateOrder()}>
                            <IntlMessages id="component.submit" ></IntlMessages>
                        </Button>


                    </DialogActions>
                </Dialog >
            </div >
        );
    }
}

export default withStyles(styles)(Section);
